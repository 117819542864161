<template>
    <div>
        <invoice-form :itemid="itemId" :itemtype="itemType"></invoice-form>
    </div>
</template>

<script>
import InvoiceForm from '@/components/pages/invoices/Form'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        InvoiceForm,
    },
    mixins: [dirtyForm],
    data() {
        return {
            companyFilter: null,
            itemId: this.$route.params?.itemId || null,
            itemType: this.$route.params?.itemType || null,
        }
    },
}
</script>
